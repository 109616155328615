.privacyDiv{
    width: 90%;
}

.privacyMain{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}

@media only screen and (max-width: 1100px) {
    .privacyDiv{
        padding-top: 20%;
    }
}

@media only screen and (min-width: 1100px) {
    .privacyDiv{
        padding-top: 7%;
    }
}