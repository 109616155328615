.container {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    transition: background-image 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered-div{
    width: 80%;
    color: white;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    font-size: x-large;
    margin-bottom: 10%;
}

.h1text{
    margin-bottom: 0;
}

.subtext{
    margin: 0 0 20% 0;
}