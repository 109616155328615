.backBtn{
    background-color: #0067aa;
    color: white;
    border: 1px solid #0067aa;
    border-radius: 10px;
    padding: 10px 22px 10px 22px;
    cursor: pointer;
    transition: all 0.5s;
}

.backBtn:hover{
    background-color: #1e2f97;
}

.fill{
    padding-left: 100px;
    padding-right: 100px;
}

@media only screen and (max-width: 1100px) {
    .fill {
        padding-top: 25%;
        height: 35vh;
    }
}

@media only screen and (min-width: 1100px) {
    .fill {
        padding-top: 15%;
        height: 40vh;
    }
}