.blueFooter {
  background-color: #0067aa;
}

.blueFooterP {
  color: white;
  padding: 10px;
  padding-left: 10%;
  margin: 0;
}

.blueFooterP2 {
    color: white;
    padding: 10px;
    padding-right: 10%;
    margin: 0;
    cursor: pointer;
}

.font {
  font-family: "Poppins", sans-serif;
}

.blackFooter{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    background-color: black;
}

.BF1{
    padding-right: 2%;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: black;
    color: white;
}

.BF2{
    background-color: black;
    color: white;
    margin: 2%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

@media only screen and (max-width: 1000px) {
    .blackFooter {
        flex-direction: column;
    }
    .rightBorder{
        border: none
    }
    .footerLogo{
        width: 40%;
    }
    .BF1 {
        margin: 2% 2% 2% 2%;
    }
}

@media only screen and (min-width: 1000px) {
    .blackFooter {
        flex-direction: row;
    }
    .rightBorder{
        border-right: 0.5px solid white;
    }
    .footerLogo{
        width: 50%;
    }
    .BF1 {
        width: 100%;
        margin: 2% 0 2% 2%;
    }
    .BF2 {
        width: 100%;
    }
}

.privacy{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}