.carsMainDiv {
  display: grid;
  gap: 10px;
  justify-items: center;
}

.carContainer,
.carContainer2 {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  word-wrap: break-word;
}

@media (max-width: 1100px) {
  .carsMainDiv {
    grid-template-columns: auto;
  }
  .carContainer {
    width: 20rem;
  }
  .additionalDiv {
    flex-direction: column;
  }
  .fillerDiv {
    margin-top: 3%;
  }
  .insuranceDiv {
    margin-top: 3%;
  }
  .formDiv {
    margin-top: 3%;
  }
}

@media (min-width: 1100px) {
  .additionalDiv {
    flex-direction: row;
  }
  .fillerDiv {
    margin-left: 1%;
  }
  .insuranceDiv {
    margin-left: 1%;
  }
  .formDiv {
    margin-left: 1%;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .carsMainDiv {
    grid-template-columns: auto auto auto auto;
  }
  .carContainer {
    width: 15rem;
  }
}

@media (min-width: 1300px) {
  .carsMainDiv {
    grid-template-columns: auto auto auto auto;
  }
  .insuranceDiv {
    width: 70%;
  }
  .formDiv {
    width: 70%;
  }
}

@media (min-width: 1300px) and (max-width: 1600px) {
  .carContainer {
    width: 20rem;
  }
}

@media (min-width: 1600px) {
  .carContainer {
    width: 25rem;
  }
}

.additionalDiv {
  grid-column: 1 / -1;
  background-color: #f0f0f0;
  padding: 10px;
  width: 97%;
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.3);
  background-color: #ededed;
  border-radius: 10px;
  margin-bottom: 3%;
  margin-top: 3%;
  display: flex;
}

.imageDiv {
  display: flex;
  justify-content: center;
}

.whiteBG {
  background-color: white;
}

.underline {
  text-decoration: underline;
}

.insuranceDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fillerDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  word-wrap: break-word;
}

.formDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  word-wrap: break-word;
}

.centerDiv {
  width: 100%;
  text-align: center;
}

.cardName {
  text-align: left;
}

.cardName2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cardMileage {
  text-align: left;
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.cardNameP {
  margin: 0;
}

.iconx {
  margin-right: 5px;
  cursor: pointer;
}

.cardIcons {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.cardIconDiv {
  color: white;
  background-color: gray;
  padding: 0.5% 1.5% 0.5% 1.5%;
  border-radius: 20px;
}

.cardImage {
  width: 100%;
}

.insuranceItem {
  border: 1px solid black;
  border-radius: 20px;
  padding: 25px;
  margin-bottom: 3%;
  width: 80%;
}

.nextBtn {
  width: 30%;
  border: 1px solid transparent;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px 0 10px 0;
  background-color: #0067aa;
  color: white;
  transition: 1s all;
  margin-top: 1%;
}

.nextBtn:hover {
  background-color: transparent;
  color: #0067aa;
  border: 1px solid #0067aa;
}

.radioBtn {
  width: 15px;
  height: 15px;
}

.hideInput {
  display: none;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 0 2% 0 2%;
  text-align: left;
  height: 100%;
}

.input {
  height: 30px;
  border-radius: 10px;
  font-size: 20px;
  border: 1px solid gray;
}

.dateinput {
  width: 60%;
  height: 30px;
  border-radius: 10px;
  font-size: 20px;
  border: 1px solid gray;
}

.input:focus {
  outline: none;
}

.PhoneInputInput {
  height: 30px;
  border-radius: 10px;
  font-size: 20px;
  border: 1px solid gray;
}

.dateLocation {
  display: flex;
  gap: 5px;
}

.inputSelect {
  width: 40%;
  height: 30px;
  border-radius: 10px;
  font-size: 20px;
  border: 1px solid gray;
}
