.topbar {
  background-color: black;
  color: white;
  flex-direction: row;
  justify-content: space-evenly;
}

.font {
  font-family: "Poppins", sans-serif;
}

.mr-20 {
  margin-right: 20%;
}

.logo {
  cursor: pointer;
}

.blue {
  color: #0067aa;
  align-self: center;
  transition: color 0.5s;
  text-decoration: none;
  cursor: pointer;
}

.blue:hover {
  color: white;
}

.header {
  background-color: white;
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: space-evenly;
  opacity: 75%;
  transition: all 0.3s ease;
  height: 100px;
  width: 100%;
}

.header.scrolled {
  height: 80px;
  position: fixed;
  top: 0;
  transition: all 0.3s ease;
}

.header-text {
  align-self: center;
  color: black;
  transition: color 0.5s;
  cursor: pointer;
}

.header-text:hover {
  color: #0067aa;
}

.home-text {
  cursor: pointer;
  color: #0067aa;
  align-self: center;
}

.icon {
  margin-right: 5px;
}

.flag {
  height: 20px;
  width: 30px;
}

.center {
  display: flex;
  align-items: center;
}

.select {
  border: none;
  font-size: 17px;
  text-decoration: none;
  outline: none;
}

@media (max-width: 1100px) {
  .topbar, .widefalse, .wide {
    display: none;
  }
  .hamburger {
    display: flex;
  }
}

@media (min-width: 1100px) {
  .topbar, .wide {
    display: flex;
  }
  .hamburger {
    display: none;
  }
}

.menu-icon{
  color: white;
  padding: 20px;
}

.menu-item{
  font-weight: bold;
  color: white;
  padding: 20px;
  border-bottom: 1px solid white;
  text-decoration: none;
  cursor: pointer;
}

.widetrue{
  padding: 20px
}

.menu{
  background-color: rgba(0, 0, 0, 1);
}

.bm-burger-button, .bm-cross-button{
  display: none;
}