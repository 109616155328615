.aboutMainDiv{
    display: grid;
    justify-items: center;
    text-align: center;
}

.centerTxt{
    text-align: center;
    font-size: large;
}

@media (max-width: 1100px) {
    .aboutMainDiv{
        grid-template-columns: auto;
    }
    .aboutGridItem{
        width: 80%;
    }
}
  
@media (min-width: 1100px) {
    .aboutMainDiv{
        grid-template-columns: auto auto;
    }
    .aboutGridItem{
        width: 500px;
    }
}

.aboutGridItem{
    background-color: #ededed;
    border-radius: 10px;
    margin-bottom: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.3);
}

.iconHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    transition: color 0.5s;
}

.iconHeader:hover{
    color: #0067aa;
}